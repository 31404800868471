import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { CheckoutService } from "./core/services/checkout.service";
import { HttpClientModule } from "@angular/common/http";
import { SidebarItemComponent } from "./events/sidebar-item/sidebar-item.component";
import { EventReportsComponent } from "./events/event-reports/event-reports.component";
import { SupervisorEventComponent } from "./supervisor-event/supervisor-event.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
		HttpClientModule,
		NgSelectModule,
    ReactiveFormsModule
	],
	exports: [],
	providers: [CheckoutService],
	bootstrap: [AppComponent],
})
export class AppModule {}
