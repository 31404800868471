// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBK8FhcBeXlkUgVEg51Bc-RyL67X0s9PQ4',
    authDomain: 'clicket-bo.firebaseapp.com',
    projectId: 'clicket-bo',
    storageBucket: 'clicket-bo.appspot.com',
    messagingSenderId: '932391470339',
    appId: '1:932391470339:web:86205a9744dd4804051536',
    measurementId: 'G-C7LJTS7YGH'
  },
  posUrl: 'http://localhost:4200',
  // invoiceUrl: 'https://us-central1-clicket-bo.cloudfunctions.net/magicGenerateTickets',
  invoiceUrl: 'https://us-central1-clicket-bo.cloudfunctions.net/magicGeneratePosTickets',
  version: '1.7.12',
  functionsUrl: "https://us-central1-clicket-bo.cloudfunctions.net"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
