import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./core/authentication/authentication.guard";

const routes: Routes = [
	{
		path: "login",
		loadChildren: () => import("../app/login/login.module").then((m) => m.LoginModule),
	},
	{
		path: "magic-login",
		loadChildren: () => import("../app/magic-login/magic-login.module").then((m) => m.MagicLoginModule),
	},
	{
		path: "eventos",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/events/events.module").then((m) => m.EventsModule),
	},
	{
		path: "supervisor",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/supervisor/supervisor.module").then((m) => m.SupervisorModule),
	},
	{
		path: "supervisor/:id",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/supervisor-event/supervisor-event.module").then((m) => m.SupervisorEventModule),
	},
	{
		path: "evento/:id/tickets",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/sales2/sales.module").then((m) => m.SalesModule),
	},
  {
		path: "screen",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/screen/screen.module").then((m) => m.ScreenModule),
	},
	{
		path: "wolipay",
		canActivate: [AuthenticationGuard],
		loadChildren: () => import("../app/qr-simple/sales.module").then((m) => m.SalesModule),
	},
  {
    path: '',
    redirectTo: '/eventos',
    pathMatch: 'full'
  },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
