import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const authState: Observable<any> = this.firebaseAuth.authState.pipe(
      take(1)
    );
    return authState.pipe(
      map(user => {
        if (state.url === '/login') {
          const falseCondition: boolean = user !== null;
          return this.checkLogIn(falseCondition, state);
        }
        return this.checkLogIn(user === null, state);
      })
    );
  }

  private checkLogIn(falseCondition: boolean, state): boolean {
    if (falseCondition) {
      this.authenticationService.loginSuccess = false;
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    this.authenticationService.loginSuccess = true;
    return true;
  }
}
